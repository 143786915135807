<template>
	<div class="detail-card bg-white border">
		<div
			class="card--header d-flex align-items-center pa-2 border-bottom"
			v-on:click="open_detail = !open_detail"
		>
			<div class="font-level-3-bold">Client Score</div>
			<v-spacer></v-spacer>
			<v-icon>{{ open_detail ? "mdi-menu-down" : "mdi-menu-right" }}</v-icon>
		</div>
		<div class="card--body d-flex align-items-center pa-2" v-if="open_detail">
			<table class="w-100">
				<tr>
					<td class="fw-500 px-3 py-2">Renew Rating</td>
					<td class="fw-600 px-3 py-1">
						<v-rating
							v-if="clientScore"
							v-model="clientScore.client_score_renew"
							background-color="blue darken-4"
							color="blue darken-4"
							hide-details
							readonly
						></v-rating>
					</td>
				</tr>
				<tr>
					<td class="fw-500 px-3 py-2">Full</td>
					<td class="fw-600 px-3 py-1">
						<v-rating
							v-if="clientScore"
							v-model="clientScore.client_score_full"
							background-color="blue darken-4"
							color="blue darken-4"
							hide-details
							readonly
						></v-rating>
					</td>
				</tr>
				<tr>
					<td class="fw-500 px-3 py-2">Base Only</td>
					<td class="fw-600 px-3 py-1">
						<v-rating
							v-if="clientScore"
							v-model="clientScore.client_score_base"
							background-color="blue darken-4"
							color="blue darken-4"
							hide-details
							readonly
						></v-rating>
					</td>
				</tr>
			</table>
		</div>
	</div>
</template>
<script>
export default {
	name: "ClientScore",
	props: {
		clientScore: {
			type: Object,
		},
	},
	data() {
		return {
			open_detail: true,
			renew_rating: 3,
		};
	},
};
</script>
